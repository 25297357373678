<template>
	<div class='contain'>
		<img src="@/assets/imgs/com-auth-bg.png" alt="">
		<div class="main">
			<div class="fs_24 title">企业信息</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :hide-required-asterisk="true">
				<el-form-item label="企业名称" prop="company">
					<el-input placeholder="请输入企业名称" class="flex_c" type="text" v-model.trim="ruleForm.company" @input="search"></el-input>
					<div class="fs_14 color_999" v-if="notCompany==1"><i class="el-icon-error fs_16 mr_5" style="color:#ff4e4e;"></i>抱歉该企业查询不到</div>
					<div class="flex" v-else>
						<div class="fs_14 color_333" v-show="ruleForm.creditcode">信用代码 {{ ruleForm.creditcode }}</div>
					</div>
				</el-form-item>
				<!-- 远程搜索列表 -->
				<el-collapse-transition>
					<div class="states" v-if="showStates">
						<p class="padding_15 bgf fs_14 color_333 item bottom_link pointer" v-for="(item, index) in companyList" :key="index" @click="selectCompany(item)">{{ item.name }}</p>
					</div>
				</el-collapse-transition>

				<div v-if="notCompany == 2">
					<div class="flex_c fs_16 color_333"><img src="../../assets/imgs/icon/in-icon.png" class="in-icon" alt=""> 该企业已认证，加入企业请联系以下人员：</div>
					<div class="admin-list fs_14 color_333 padding_15">
						<p class="mb_10" v-for="(item, index) in companyInfos" :key="index">{{ index+1 }}、{{ item.realname }} {{ item.mobile }}</p>
					</div>
				</div>
				<div class="flex_cen">
                    <el-button type="default" class="fs_18" @click="toIndex">体验看看</el-button>
					<el-button type="primary" class="fs_18" :class="{disabled:!ruleForm.creditcode}" :disabled="!ruleForm.creditcode" @click="submit">{{ btnText }}</el-button>
                </div>
			</el-form>
		</div>
	</div>
</template>

<script>
import { s_setItem } from '@/common/cache'
export default {
	name: 'Create',
	data() {
		return {
			showStates: false, // 远程搜索选择框
			notCompany: 0, // 搜索不到企业
			btnText: '校 验', // 下一步、提交申请
			companyList: [], // 企业列表
			companyInfos: [],
			ruleForm: {
				creditcode: '', // 信用代码
				company: ''
			},
			rules: {
				company: [
					{ required: true, message: '请输入企业名称', trigger: 'change' }
				]
			}
		}
	},
	methods: {
		/* 远程搜索 */
		search() {
			if (!this.ruleForm.company) {
				this.ruleForm.creditcode = ''
				return
			}
			this.notCompany = 0
			this.ruleForm.creditcode = ''
			this.btnText = '校 验'
			this.$axios({
				url: '/ent/v3/usercenter/company/search',
				method: 'GET',
				data: {
					keyword: this.ruleForm.company
				},
				shake: true,
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.companyList = res.data
					this.showStates = true
				}
			})
		},
		/* 选择企业 */
		selectCompany(item) {
			this.showStates = false
			this.ruleForm.company = item.name
			this.ruleForm.creditcode = item.creditCode
		},
		/* 效验企业 */
		validate() {
			this.$axios({
				url: '/ent/v3/usercenter/company/find',
				method: 'GET',
				data: {
					creditcode: this.ruleForm.creditcode
				},
				shake: true,
				hideMessage: true
			}).then(res => {
				if (res.code === 200) { // 用户认证企业
					s_setItem('authInfo', res.data)
					this.$router.push(`/auth`)
				}else if(res.code === 4032004) { // 企业已存在
					this.companyInfos = res.data.members
					this.btnText = '重新校验'
					this.notCompany = 2
				}else if(res.code === 4032003 || res.code === 4032002) { // 企业审核中 或 已拒绝
					this.$confirm(`${res.msg}`, '提示', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: `${res.code === 4032003 ? '查看审核详情' : '查看审核进度'}`,
						cancelButtonText: '取 消',
						type: 'warning'
					}).then(() => {
						this.$router.push(`/inReview`)
					})
				}else if(res.code === 4032000) { // 企业已认证
					this.$confirm(`${res.msg}`, '提示', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: '查看企业信息',
						cancelButtonText: '取 消',
						type: 'warning'
					}).then(() => {
						this.$router.replace('/account')
					})
				}else {
					this.$message.error(res.msg)
				}
			})
		},
		submit() {
			if (!this.ruleForm.creditcode) return
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					if (this.notCompany === 0) {
						this.validate()
					} else if (this.notCompany === 2) {
						this.ruleForm.company = ''
						this.notCompany = 0
						this.btnText = '校 验'
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		toIndex() {
			this.$router.replace('/index')
		}
	}
}
</script>

<style lang='less' scoped>

.main {
	padding: 20px 100px 100px;
	background-color: #fff;
	.title {
		padding: 20px 0;
		margin-bottom: 18px;
		border-bottom: 1px dashed #EDEDED;
	}
	/deep/.el-input__inner {
        height: 52px;
        margin-bottom: 5px;
        background: #f9f9f9;
        padding-left: 20px;
        font-size: 14px;
    }
}
/deep/.el-form-item__label {
	font-size: 18px;
	color: #333;
}
.disabled {
	background: #e3e3e3 !important;
	border: none !important;
}
.el-form {
	position: relative;
	.states {
		position: absolute;
		width: 100%;
		height: auto;
		left: 0;
		top: 86px;
		z-index: 2;
		box-shadow: 0px 3px 20px rgba(158, 158, 158, 0.16);
		max-height: 294px;
		overflow-y: scroll;
		.item {
            transition: color .2s ease;
            &:hover {
                color: #1890ff;
                transition: color .2s ease;
            }
        }
	}
	/deep/.el-input__inner {
		margin-bottom: 0;
	}
	.el-button {
        width: 200px;
        height: 52px;
        margin-top: 80px;
	}
	.in-icon {
		width: 18px;
		height: 18px;
		margin-right: 10px;
	}
	.admin-list {
		padding-left: 30px;
	}
}
</style>